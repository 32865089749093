import {
  Box,
  Chip,
  Icon,
  IconButton,
  Icons,
  Line,
  PopOut,
  RectCords,
  Scroll,
  Spinner,
  Text,
  as,
  config,
} from 'folds';
import {isKeyHotkey} from 'is-hotkey';
import {useAtom} from 'jotai';
import {IContent, MatrixEvent, RelationType, Room} from 'matrix-js-sdk';
import {MouseEvent, useRef} from 'react';
import {KeyboardEventHandler, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Editor, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';
import {CustomIcon} from '~/app/atoms/system-icons/CustomIcon';
import {CustomIcons} from '~/app/atoms/system-icons/CustomIconsStore';
import {UseStateProvider} from '~/app/components/UseStateProvider';
import {
  AUTOCOMPLETE_PREFIXES,
  AutocompletePrefix,
  AutocompleteQuery,
  CustomEditor,
  EmoticonAutocomplete,
  TaskMentionAutocomplete,
  Toolbar,
  UserMentionAutocomplete,
  createEmoticonElement,
  customHtmlEqualsPlainText,
  getAutocompleteQuery,
  getPrevWorldRange,
  htmlToEditorInput,
  moveCursor,
  plainToEditorInput,
  removeMobileMxReply,
  toMatrixCustomHTML,
  toPlainText,
  trimCustomHtml,
  useEditor,
} from '~/app/components/editor';
import {IssueMentionAutocomplete} from '~/app/components/editor/autocomplete/IssuMentionAutocomplete';
import {EmojiBoard} from '~/app/components/emoji-board';
import {
  EditUploadBoard,
  EditUploadBoardContent,
  EditUploadBoardHeader,
} from '~/app/components/upload-board/EditUploadBoard';
import {UploadCardRenderer} from '~/app/components/upload-card';
import {AsyncStatus, useAsyncCallback} from '~/app/hooks/useAsyncCallback';
import {useFilePicker} from '~/app/hooks/useFilePicker';
import {useIssueById} from '~/app/hooks/useGetIssueById';
import {useTaskById} from '~/app/hooks/useGetTaskById';
import {Actions, useActionHandler} from '~/app/hooks/useHandleAction';
import {useHandleFiles} from '~/app/hooks/useHandleFiles';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {useOutlineSortKeyMap} from '~/app/hooks/useOutlineSortkey';
import {usePrefixQuery} from '~/app/hooks/usePrefixQuery';
import {useSendUpload} from '~/app/hooks/useSendUpload';
import {UploadBoardImperativeHandlers} from '~/app/hooks/useUploadSendHandler';
import {useSetting} from '~/app/state/hooks/settings';
import {hasMentionAtom} from '~/app/state/mention';
import {
  editRoomUploadAtomFamily,
  roomEditIdToUploadItemsAtomFamily,
} from '~/app/state/room/roomInputDrafts';
import {settingsAtom} from '~/app/state/settings';
import {Upload, createEditUploadFamilyObserverAtom} from '~/app/state/upload';
import {UploadStatus} from '~/app/state/upload';
import {handleDataBycoreContentEdit} from '~/app/utils/customEvent';
import {sentryLog} from '~/app/utils/logger';
import {TUploadContent} from '~/app/utils/matrix';
import {getEditedEvent, removeBlockQuoteFromEditor} from '~/app/utils/room';
import {mobileOrTablet} from '~/app/utils/user-agent';
import {DATA_BYCORE_ISSUE_ID, DATA_BYCORE_TASK_ID} from '~/client/state/constants';
import {useTasksByProject} from '~/queries/useTasksByProject';
import {useProjectAndGroupIdFromWorkerChats} from '~/queries/useWorkerChats';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {Attachment, MRelatesToContent} from '~/types/matrix/room';

type MessageEditorProps = {
  roomId: string;
  room: Room;
  mEvent: MatrixEvent;
  imagePackRooms?: Room[];
  onCancel: () => void;
};

export const MessageEditor = as<'div', MessageEditorProps>(
  ({room, roomId, mEvent, imagePackRooms, onCancel, ...props}, ref) => {
    const {t} = useTranslation(['message']);
    const mx = useMatrixClient();
    const editor = useEditor();
    const [enterForNewline] = useSetting(settingsAtom, 'enterForNewline');
    const [globalToolbar] = useSetting(settingsAtom, 'editorToolbar');
    const [isMarkdown] = useSetting(settingsAtom, 'isMarkdown');
    const [toolbar] = useState(globalToolbar);
    const [, setHasMention] = useAtom(hasMentionAtom);
    const {data: chatData} = useProjectAndGroupIdFromWorkerChats(room.roomId ?? '');
    const [autocompleteQuery, setAutocompleteQuery] =
      useState<AutocompleteQuery<AutocompletePrefix>>();
    const getIssueById = useIssueById({
      projectId: chatData?.projectId,
    });
    const {data: tasksList} = useTasksByProject(chatData?.projectId ?? '');
    const getTaskById = useTaskById({
      projectId: chatData?.projectId,
    });
    const {oskMap} = useOutlineSortKeyMap(tasksList);
    const [selectedFiles, setSelectedFiles] = useAtom(roomEditIdToUploadItemsAtomFamily(roomId));
    const [uploadBoard, setUploadBoard] = useState(true);
    const uploadBoardHandlers = useRef<UploadBoardImperativeHandlers>();

    const handleFiles = useHandleFiles({
      roomId,
      setUploadBoard,
      setSelectedFiles,
    });

    const {handleSendUpload, processUploadResult} = useSendUpload({
      mx,
      roomId,
      editor,
      selectedFiles,
      setSelectedFiles,
      uploadBoardHandlers,
    });

    const pickFile = useFilePicker(handleFiles, true);

    /**
     * This value is not tracked during the edit phase because
     * the user cannot toggle the checkbox in RoomTimeline to mark as public.
     * It is only set and used outside of the edit phase.
     */
    setHasMention(false);

    const getPrevBodyAndFormattedBody = useCallback((): [
      string | undefined,
      string | undefined,
    ] => {
      const evtId = mEvent.getId()!;
      const evtTimeline = room.getTimelineForEvent(evtId);
      const editedEvent =
        evtTimeline && getEditedEvent(evtId, mEvent, evtTimeline.getTimelineSet());

      const {body, formatted_body: customHtml}: Record<string, unknown> =
        editedEvent?.getContent()['m.new_content'] ?? mEvent.getContent();

      const trimmedCustomHTML =
        typeof customHtml === 'string'
          ? removeBlockQuoteFromEditor(removeMobileMxReply(customHtml))
          : undefined;

      return [typeof body === 'string' ? body : undefined, trimmedCustomHTML];
    }, [room, mEvent]);

    const [saveState, save] = useAsyncCallback(
      useCallback(async () => {
        const plainText = toPlainText(editor.children).trim();
        const customHtml = trimCustomHtml(
          toMatrixCustomHTML(editor.children, {
            allowTextFormatting: true,
            allowBlockMarkdown: isMarkdown,
            allowInlineMarkdown: isMarkdown,
          }),
        );

        const [prevBody, prevCustomHtml] = getPrevBodyAndFormattedBody();
        const prevContent = mEvent.getContent();

        // Check if we're only adding attachments without text changes
        const isOnlyAttachmentChange =
          prevBody === plainText && (!prevCustomHtml || prevCustomHtml === customHtml);

        const sendResult = await uploadBoardHandlers.current?.handleSend();
        const hasNewAttachments = Boolean(sendResult?.length);
        const newAttachments = hasNewAttachments ? await processUploadResult() : [];

        // If no text changes and no new attachments, return early
        if (isOnlyAttachmentChange && !hasNewAttachments) {
          return undefined;
        }

        // If empty text but has attachments, send only attachments
        if (plainText === '' && hasNewAttachments) {
          const content: IContent = {
            msgtype: prevContent.msgtype,
            body: prevContent.body || '',
            attachments: newAttachments,
          };
          if (prevContent.formatted_body) {
            content.format = 'org.matrix.custom.html';
            content.formatted_body = prevContent.formatted_body;
          }
          return mx.sendMessage(roomId, content);
        }

        const newContent: IContent = {
          msgtype: prevContent.msgtype,
          body: plainText,
        };

        if (!customHtmlEqualsPlainText(customHtml, plainText)) {
          newContent.format = 'org.matrix.custom.html';
          newContent.formatted_body = customHtml;
        }

        const inReplyTo = prevContent?.[MRelatesToContent.RelatesTo]?.[MRelatesToContent.InReplyTo];
        const hasReplyAttachments = room
          .findEventById(inReplyTo?.event_id as string)
          ?.getContent<{attachments: Array<Attachment>}>()?.attachments?.length;

        // Combine existing and new attachments
        newContent.attachments = hasReplyAttachments
          ? newAttachments // If there are reply attachments, only use new attachments
          : [...(prevContent?.attachments ?? []), ...newAttachments]; // Otherwise combine both

        const content: IContent = {
          ...newContent,
          body: isOnlyAttachmentChange ? prevContent.body : `* ${plainText}`,
          formatted_body: isOnlyAttachmentChange ? prevContent.formatted_body : customHtml,
          'm.new_content': newContent,
          'm.relates_to': {
            event_id: mEvent.getId(),
            rel_type: RelationType.Replace,
          },
        };

        const hasDataBycoreIssueId = content.formatted_body?.includes(DATA_BYCORE_ISSUE_ID);
        const hasDataBycoreTaskId = content.formatted_body?.includes(DATA_BYCORE_TASK_ID);

        if (hasDataBycoreIssueId || hasDataBycoreTaskId) {
          try {
            await handleDataBycoreContentEdit({
              content,
              roomId,
              mx,
              oskMap,
              getIssueById,
              getTaskById,
              isMentionPublic: prevContent.mention_is_not_public,
            });

            setSelectedFiles({type: 'RESET'});
            return;
          } catch (err) {
            sentryLog.error('Failed to send data bycore content', err, {
              tags: {
                name: 'MessageEditor.save',
              },
            });
          }
        }

        setSelectedFiles({type: 'RESET'});
        return mx.sendMessage(roomId, content);
      }, [
        mx,
        editor,
        roomId,
        mEvent,
        isMarkdown,
        getIssueById,
        getTaskById,
        getPrevBodyAndFormattedBody,
        oskMap,
        uploadBoardHandlers,
        processUploadResult,
      ]),
    );

    const handleCancel = useCallback(() => {
      mixpanelService.trackWithAction(onCancel, MixpanelEvents.MessageContextEditCancelClick);
    }, [onCancel]);

    const handleSave = useCallback(() => {
      if (saveState.status !== AsyncStatus.Loading) {
        mixpanelService.trackWithAction(save, MixpanelEvents.MessageContextEditSaveClick);
      }
    }, [saveState, save]);

    const handleKeyDown: KeyboardEventHandler = useCallback(
      (evt) => {
        if (isKeyHotkey('mod+enter', evt) || (!enterForNewline && isKeyHotkey('enter', evt))) {
          evt.preventDefault();
          handleSave();
        }
        if (isKeyHotkey('escape', evt)) {
          evt.preventDefault();
          handleCancel();
        }
      },
      [handleCancel, handleSave, enterForNewline],
    );

    const handleKeyUp: KeyboardEventHandler = useCallback(
      (evt) => {
        if (isKeyHotkey('escape', evt)) {
          evt.preventDefault();
          return;
        }

        const prevWordRange = getPrevWorldRange(editor);
        const query = prevWordRange
          ? getAutocompleteQuery<AutocompletePrefix>(editor, prevWordRange, AUTOCOMPLETE_PREFIXES)
          : undefined;
        setAutocompleteQuery(query);
      },
      [editor],
    );
    const handlePrefixQuery = usePrefixQuery({editor, handleKeyUp});

    const handleCloseAutocomplete = useCallback(() => {
      ReactEditor.focus(editor);
      setAutocompleteQuery(undefined);
    }, [editor]);

    const handleEmoticonSelect = (key: string, shortcode: string) => {
      editor.insertNode(createEmoticonElement(key, shortcode));
      moveCursor(editor);
    };

    const uploadFamilyObserverAtom = createEditUploadFamilyObserverAtom(
      editRoomUploadAtomFamily,
      selectedFiles.map((f) => f.file),
    );

    const handleRemoveUpload = useCallback(
      (upload: TUploadContent | TUploadContent[]) => {
        const uploads = Array.isArray(upload) ? upload : [upload];
        setSelectedFiles({
          type: 'DELETE',
          item: selectedFiles.filter((f) => uploads.find((u) => u === f.file)),
        });
        uploads.forEach((u) => editRoomUploadAtomFamily.remove(u));
      },
      [setSelectedFiles, selectedFiles],
    );

    const handleCancelUpload = (uploads: Upload[]) => {
      uploads.forEach((upload) => {
        if (upload.status === UploadStatus.Loading) {
          mx.cancelUpload(upload.promise);
        }
      });
      handleRemoveUpload(uploads.map((upload) => upload.file));
    };

    const actions: Actions = {
      image: {
        action: () => pickFile('image/jpeg,image/png,image/jpg'),
        event: MixpanelEvents.MessageBoxPhotoButtonClick,
      },
      userMention: {
        action: () => handlePrefixQuery(AutocompletePrefix.UserMention),
        event: MixpanelEvents.MessageBoxMentionUnselectClick,
      },
      activityMention: {
        action: () => handlePrefixQuery(AutocompletePrefix.TaskMention),
        event: MixpanelEvents.MessageBoxActivityMentionClick,
      },
      issueMention: {
        action: () => handlePrefixQuery(AutocompletePrefix.Issue),
        event: MixpanelEvents.MessageBoxIssueMentionClick,
      },
    };

    const handleAction = useActionHandler({
      actions,
      dependencies: [handlePrefixQuery],
    });

    useEffect(() => {
      const [body, customHtml] = getPrevBodyAndFormattedBody();

      const initialValue =
        typeof customHtml === 'string'
          ? htmlToEditorInput(customHtml)
          : plainToEditorInput(typeof body === 'string' ? body : '');

      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      });

      editor.insertFragment(initialValue);
      if (!mobileOrTablet()) ReactEditor.focus(editor);
    }, [editor, getPrevBodyAndFormattedBody]);

    useEffect(() => {
      if (saveState.status === AsyncStatus.Success) {
        handleCancel();
      }
    }, [saveState, handleCancel]);

    return (
      <div {...props} ref={ref}>
        {selectedFiles.length > 0 && (
          <EditUploadBoard
            header={
              <EditUploadBoardHeader
                open={uploadBoard}
                onToggle={() => setUploadBoard(!uploadBoard)}
                uploadFamilyObserverAtom={uploadFamilyObserverAtom}
                onSend={handleSendUpload}
                imperativeHandlerRef={uploadBoardHandlers}
                onCancel={handleCancelUpload}
              />
            }
          >
            {uploadBoard && (
              <Scroll size="300" hideTrack visibility="Hover">
                <EditUploadBoardContent>
                  {Array.from(selectedFiles)
                    .reverse()
                    .map((fileItem, index) => (
                      <UploadCardRenderer
                        // biome-ignore lint/suspicious/noArrayIndexKey:
                        key={(fileItem.file as File).name + index}
                        file={fileItem.file}
                        isEncrypted={!!fileItem.encInfo}
                        uploadAtom={editRoomUploadAtomFamily(fileItem.file)}
                        onRemove={handleRemoveUpload}
                      />
                    ))}
                </EditUploadBoardContent>
              </Scroll>
            )}
          </EditUploadBoard>
        )}
        {autocompleteQuery?.prefix === AutocompletePrefix.TaskMention ? (
          <TaskMentionAutocomplete
            roomId={roomId}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        {autocompleteQuery?.prefix === AutocompletePrefix.UserMention ? (
          <UserMentionAutocomplete
            room={room}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        {autocompleteQuery?.prefix === AutocompletePrefix.Emoticon ? (
          <EmoticonAutocomplete
            imagePackRooms={imagePackRooms || []}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        {autocompleteQuery?.prefix === AutocompletePrefix.Issue ? (
          <IssueMentionAutocomplete
            room={room}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        <CustomEditor
          editor={editor}
          placeholder="Edit message..."
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          bottom={
            <>
              <Box
                style={{padding: config.space.S200, paddingTop: 0}}
                alignItems="End"
                justifyContent="SpaceBetween"
                gap="100"
              >
                <Box gap="Inherit">
                  <Chip
                    onClick={handleSave}
                    variant="Primary"
                    radii="Pill"
                    disabled={saveState.status === AsyncStatus.Loading}
                    outlined
                    after={
                      saveState.status === AsyncStatus.Loading ? (
                        <Spinner variant="Primary" fill="Soft" size="100" />
                      ) : undefined
                    }
                  >
                    <Text size="B300">{t('edit_message.cta.save')}</Text>
                  </Chip>
                  <Chip onClick={handleCancel} variant="SurfaceVariant" radii="Pill">
                    <Text size="B300">{t('edit_message.cta.cancel')}</Text>
                  </Chip>
                </Box>
                <Box gap="Inherit">
                  <IconButton
                    onClick={() => handleAction('image')}
                    variant="SurfaceVariant"
                    size="300"
                    radii="300"
                  >
                    <Icon src={Icons.Plus} />
                  </IconButton>
                  <IconButton
                    variant="SurfaceVariant"
                    size="300"
                    radii="300"
                    onClick={() => handleAction('userMention')}
                  >
                    <Icon src={Icons.User} />
                  </IconButton>
                  <IconButton
                    variant="SurfaceVariant"
                    size="300"
                    radii="300"
                    onClick={() => handleAction('activityMention')}
                  >
                    <CustomIcon src={CustomIcons.Clipboard} />
                  </IconButton>
                  <IconButton
                    variant="SurfaceVariant"
                    size="300"
                    radii="300"
                    onClick={() => handleAction('issueMention')}
                  >
                    <CustomIcon src={CustomIcons.Warning} />
                  </IconButton>
                  <UseStateProvider initial={undefined}>
                    {(anchor: RectCords | undefined, setAnchor) => (
                      <PopOut
                        anchor={anchor}
                        alignOffset={-8}
                        position="Top"
                        align="End"
                        content={
                          <EmojiBoard
                            imagePackRooms={imagePackRooms ?? []}
                            returnFocusOnDeactivate={false}
                            onEmojiSelect={handleEmoticonSelect}
                            onCustomEmojiSelect={handleEmoticonSelect}
                            requestClose={() => {
                              setAnchor(undefined);
                              if (!mobileOrTablet()) ReactEditor.focus(editor);
                            }}
                          />
                        }
                      >
                        <IconButton
                          aria-pressed={anchor !== undefined}
                          onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                            const buttonRect = evt.currentTarget.getBoundingClientRect();
                            mixpanelService.trackWithAction(() => {
                              setAnchor(buttonRect);
                            }, MixpanelEvents.MessageContextEditEmojiClick);
                          }}
                          variant="SurfaceVariant"
                          size="300"
                          radii="300"
                        >
                          <Icon size="400" src={Icons.Smile} filled={anchor !== undefined} />
                        </IconButton>
                      </PopOut>
                    )}
                  </UseStateProvider>
                </Box>
              </Box>
              {toolbar && (
                <div>
                  <Line variant="SurfaceVariant" size="300" />
                  <Toolbar />
                </div>
              )}
            </>
          }
        />
      </div>
    );
  },
);
